import './DockCamera.sass';
import CollapsablePanel from '../../../common/CollapsablePanel/CollapsablePanel';
import { TbCameraQuestion, TbChevronDownRight, TbPlayerStop, TbRefresh } from 'react-icons/tb';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IoCloudOfflineOutline } from 'react-icons/io5';
import { DeviceOnlineStatus } from 'src/helper/useDockList';
import { useDockVideoStream } from 'src/helper/useDockVideoStream';
import VideoStreamView from 'src/ui/Drone/VideoStreamView/VideoStreamView';
import { StageElement } from '../../OperationDashboard';
import classNames from 'classnames';
import useDeviceInfo from 'src/services/device/common/useDeviceInfo';
import useSubDevice from 'src/services/device/common/useSubDevice';
import { OrbitProgress } from 'react-loading-indicators';
import { FiMinimize2 } from 'react-icons/fi';
import { BsArrowLeftRight } from 'react-icons/bs';

const VideoSourceDevice = {
  DOCK: 'dock',
  SUB_DEVICE: 'subDevice'
}

function DockCamera({ deviceId, onSwap, onMinimize, expanded = false, subDevice = false, pip = false, minimized = false }) {
  const deviceInfo = useDeviceInfo(deviceId);
  const subDeviceInfo = useSubDevice(deviceId);

  const isDockOnline = deviceInfo?.onlineStatus === DeviceOnlineStatus.ONLINE;
  const isSubDeviceOnline = !!subDeviceInfo?.device_online_status;

  const [dockStreamConfig, setDockStreamConfig] = useState();
  const [subStreamConfig, setSubStreamConfig] = useState();
  const [isMinimized, setIsMinimized] = useState(minimized);

  const [activeVideoSource, setActiveVideoSource] = useState((subDevice || (pip && isSubDeviceOnline)) ? VideoSourceDevice.SUB_DEVICE : VideoSourceDevice.DOCK);
  const { stream: dockVideoStream, dockId: currStreamDockId, refresh: refreshDockVideo } = useDockVideoStream(dockStreamConfig, false) || {};
  const { stream: subVideoStream, refresh: refreshSubVideo } = useDockVideoStream(subStreamConfig, true) || {};

  const sourceTitle = useMemo(() => activeVideoSource === VideoSourceDevice.SUB_DEVICE ? 'Drone Camera' : 'Dock Camera', [activeVideoSource]);
  const pipTitle = useMemo(() => activeVideoSource === VideoSourceDevice.SUB_DEVICE ? 'Drone' : 'Dock', [activeVideoSource]);

  const handleSwap = () => {
    onSwap?.(subDevice ? StageElement.DOCK_DRONE : StageElement.DOCK);
  }

  const handleRefresh = useCallback(() => {
    if(activeVideoSource === VideoSourceDevice.DOCK){
      refreshDockVideo();
    } else {
      refreshSubVideo();
    }
  }, [activeVideoSource, refreshDockVideo, refreshSubVideo]);

  const handleMinimize = useCallback((e) => {
    e?.stopPropagation();
    setIsMinimized(true);
    onMinimize?.();
  }, [onMinimize]);

  const handleContainerClick = () => {
    setIsMinimized(false);
  };

  const handleSourceSwitch = useCallback((e) => {
    e?.stopPropagation();
    const newSource = activeVideoSource === VideoSourceDevice.DOCK ? VideoSourceDevice.SUB_DEVICE : VideoSourceDevice.DOCK;
    setActiveVideoSource(newSource);
  }, [activeVideoSource]);

  const connectionMessage = (activeVideoSource === VideoSourceDevice.DOCK ? dockStreamConfig : subStreamConfig) ?
    'Waiting for stream...' :
    'No video provided.';

  const connectionMessageIcon = (!(activeVideoSource === VideoSourceDevice.DOCK ? isDockOnline : isSubDeviceOnline) ?
    <IoCloudOfflineOutline className="icon" /> :
    <OrbitProgress size="small" color="#fff" text="" textColor="" />
  );

  const tools = [
    ...(!isMinimized ? [
      <FiMinimize2 onClick={handleMinimize} title="Minimize" key="minimize-stream" />
    ] : []),
  ];

  useEffect(() => {
    setDockStreamConfig(null);

    if(isDockOnline && (!dockVideoStream || currStreamDockId !== deviceId)){
      setTimeout(() => {
        setDockStreamConfig(deviceId);
      }, 10);
    }
  }, [isDockOnline, deviceId]);

  useEffect(() => {
    setSubStreamConfig(null);

    if(!isSubDeviceOnline)
      setActiveVideoSource(VideoSourceDevice.DOCK);

    if(isSubDeviceOnline && (subDevice || pip) && (!subVideoStream || currStreamDockId !== deviceId)){
      setTimeout(() => {
        setSubStreamConfig(deviceId);
      }, 10);
    }
  }, [isSubDeviceOnline, deviceId, subDevice]);

  useEffect(() => {
    setIsMinimized(minimized);
  }, [minimized]);

  return (
    <div className={classNames("dock-camera", {'expanded': expanded, 'minimized': isMinimized})} onClick={handleContainerClick}>
      <CollapsablePanel title={sourceTitle} onSwap={handleSwap} expanded={expanded} tools={tools} proxyChildren={isMinimized}>
        <div className="stream-container">
          <div className="connection-message">
              {connectionMessageIcon}
              <div className="description">{connectionMessage}</div>
          </div>
          <VideoStreamView stream={activeVideoSource === VideoSourceDevice.DOCK ? dockVideoStream : subVideoStream} className={classNames("video-view", { 'height-limited': activeVideoSource === VideoSourceDevice.SUB_DEVICE && expanded && !isMinimized })} />
          {
            (pip && (activeVideoSource === VideoSourceDevice.DOCK ? isSubDeviceOnline : isDockOnline)) 
            ? <div className="pip-container">
                <VideoStreamView stream={activeVideoSource === VideoSourceDevice.DOCK ? subVideoStream : dockVideoStream} className={classNames("pip-video-view")} onClick={handleSourceSwitch} />
                {!(activeVideoSource === VideoSourceDevice.DOCK ? subVideoStream : dockVideoStream) ? (
                  <div className="loading-container">
                    <OrbitProgress size="small" color="#b9b9b9" text="" textColor="" />
                  </div>
                ) : null}
            </div>
            : null
          }
        </div>
        {isMinimized ? (
          <div className="minimized-video-tools">
            {pip && (activeVideoSource === VideoSourceDevice.DOCK ? isSubDeviceOnline : isDockOnline) ? (
              <div className="toggle-camera-container" onClick={handleSourceSwitch} title="Switch video source">
                <BsArrowLeftRight className="toggle-icon" />
              </div>
            ) : null}
            <div className="minimized-title">{pipTitle}</div>
          </div>
        ) : null}
      </CollapsablePanel>
    </div>
  )
}

export default DockCamera